import MerchantPointsProgram from '../model/MerchantPointsProgram'
import WhatsAppBranchSettings, { WhatsAppBranchSettingsJSON } from '../model/WhatsAppBranchSettings'
import LoopError from '../store/errors/LoopError'
import { fetchWithErrors, HTTPMethods, newRequest, parseResponse, token, urlForEndpoint } from './helpers'

export const getWhatsAppSettings = async (): Promise<WhatsAppSettings> => {

  // Build request
  const url = urlForEndpoint(`whatsapp/settings`)
  const request = newRequest(HTTPMethods.GET, token())

  try {
    // Fetch
    const response = await fetchWithErrors(url, request)

    // Handle errors and return response
    const { branchSettings: branchSettingsJSON, balance } = await parseResponse(response)
    let isEnabled = false
    let branchSettings = branchSettingsJSON.map((branchSetting: WhatsAppBranchSettingsJSON) => {
      let branchSettings =  new WhatsAppBranchSettings(branchSetting)
      if (branchSettings.invoiceAutoSendEnabled) {
        isEnabled = true
      }
      return branchSettings
    })

    return {
      branchSettings,
      balance,
      isEnabled
    }

  } catch (err) {
    throw new LoopError(err, { })
  }
}

interface WhatsAppSettings {
  branchSettings: WhatsAppBranchSettings[]
  balance: number
  isEnabled: boolean
}
