import { createSelector } from 'reselect'
import { WhatsAppCreditTransactionsRequestParams } from '../../api/whatsAppCreditTransactions'
import WhatsAppCreditTransaction from '../../model/WhatsAppCreditTransaction'
import { RootReducerState } from '../'
import { WhatsAppCreditTransactionsReducerPage } from '../reducers/whatsAppCreditTransactions'

export const hashKeyForWhatsAppCreditTransactionPage = ({
                                     sorting,
                                     page,
                                     limit,
                                     search = "",
                                   }: WhatsAppCreditTransactionsRequestParams): string => `${page},${limit},${sorting},${search}`

export const whatsAppCreditTransactionsSelector = (state: RootReducerState): { [id: number]: WhatsAppCreditTransaction } => state.whatsAppCreditTransactions.byID

const errorsSelector = (state: RootReducerState): { [id: number]: Error | null } => state.whatsAppCreditTransactions.errorByID
const whatsAppCreditTransactionIsFetchingByIDSelector = (state: RootReducerState): { [id: number]: boolean } => state.whatsAppCreditTransactions.isFetchingByID

const whatsAppCreditTransactionPageSelector = (state: RootReducerState, props: any): WhatsAppCreditTransactionsReducerPage => state.whatsAppCreditTransactions.pages[hashKeyForWhatsAppCreditTransactionPage(props)] || new WhatsAppCreditTransactionsReducerPage()
export const whatsAppCreditTransactionFlashMessageSelector = (state: RootReducerState): string | null => state.whatsAppCreditTransactions.successFlashMessage

// TODO: fix for react router
export const whatsAppCreditTransactionIDFromPropsSelector = (state: RootReducerState, props: any): number => {
  return +props.whatsAppCreditTransactionID || -1
}

export const whatsAppCreditTransactionWithIDFromProps = createSelector(
  [whatsAppCreditTransactionsSelector, whatsAppCreditTransactionIDFromPropsSelector],
  (whatsAppCreditTransactions, whatsAppCreditTransactionID) => whatsAppCreditTransactions[whatsAppCreditTransactionID],
)

export const errorForWhatsAppCreditTransactionWithIDFromProps = createSelector(
  [errorsSelector, whatsAppCreditTransactionIDFromPropsSelector],
  (whatsAppCreditTransactions, whatsAppCreditTransactionID) => whatsAppCreditTransactions[whatsAppCreditTransactionID],
)

export const isFetchingForWhatsAppCreditTransactionWithIDFromProps = createSelector(
  [whatsAppCreditTransactionIsFetchingByIDSelector, whatsAppCreditTransactionIDFromPropsSelector],
  (whatsAppCreditTransactions, whatsAppCreditTransactionID) => whatsAppCreditTransactions[whatsAppCreditTransactionID] || false,
)

export const whatsAppCreditTransactionsByPageSelector = createSelector(
  [whatsAppCreditTransactionsSelector, whatsAppCreditTransactionPageSelector],
  (whatsAppCreditTransactions, page) => {
    if (page == null || page.childIDs == null) {
      return null
    }
    return page.childIDs.map(whatsAppCreditTransactionID => whatsAppCreditTransactions[whatsAppCreditTransactionID])
  },
)

export const paginationInfoByPageSelector = createSelector(
  [whatsAppCreditTransactionPageSelector],
  (page) => page.paginationInfo,
)

export const pageIsFetchingSelector = createSelector(
  [whatsAppCreditTransactionPageSelector],
  (page) => page.isFetching || false,
)

export const pageIsInitializedSelector = createSelector(
  [whatsAppCreditTransactionPageSelector],
  (page) => page.isInitialized || false,
)

export const pageErrorSelector = createSelector(
  [whatsAppCreditTransactionPageSelector],
  (page) => page.error || null,
)

