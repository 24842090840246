import { LocationChangeAction } from '@lagunovsky/redux-react-router'
import {
  WhatsAppCreditTransactionByIDErrorResponse,
  WhatsAppCreditTransactionsErrorResponse,
  WhatsAppCreditTransactionsRequestParams,
  WhatsAppCreditTransactionsResponse,
  PostWhatsAppCreditTransactionErrorResponse,
  PostWhatsAppCreditTransactionRequestParams,
  PutWhatsAppCreditTransactionErrorResponse,
  PutWhatsAppCreditTransactionRequestParams,
} from '../../api/whatsAppCreditTransactions'
import WhatsAppCreditTransaction from '../../model/WhatsAppCreditTransaction'
import PaginationInfo from '../../model/PaginationInfo'
import * as types from '../constants/ActionTypes'
import { ClearFlashMessagesAction } from './general'

export interface FetchWhatsAppCreditTransactionsAction {
  type: typeof types.FETCH_WHATSAPP_CREDIT_TRANSACTIONS
  success: undefined
  payload: WhatsAppCreditTransactionsRequestParams
}

export interface ResolveFetchWhatsAppCreditTransactionsAction {
  type: typeof types.FETCH_WHATSAPP_CREDIT_TRANSACTIONS
  success: boolean
  payload: {
    whatsAppCreditTransactions: WhatsAppCreditTransaction[]
    paginationInfo: PaginationInfo
    requestParams: WhatsAppCreditTransactionsRequestParams
  }
}

export interface RejectFetchWhatsAppCreditTransactionsAction {
  type: typeof types.FETCH_WHATSAPP_CREDIT_TRANSACTIONS
  success: boolean
  payload: {
    error: Error
    requestParams: WhatsAppCreditTransactionsRequestParams
  }
}

export interface FetchWhatsAppCreditTransactionByIDAction {
  type: typeof types.FETCH_WHATSAPP_CREDIT_TRANSACTION_BY_ID
  success: undefined
  payload: {
    whatsAppCreditTransactionID: number
  }
}

export interface ResolveFetchWhatsAppCreditTransactionByIDAction {
  type: typeof types.FETCH_WHATSAPP_CREDIT_TRANSACTION_BY_ID
  success: boolean
  payload: {
    whatsAppCreditTransaction: WhatsAppCreditTransaction
  }
}

export interface RejectFetchWhatsAppCreditTransactionByIDAction {
  type: typeof types.FETCH_WHATSAPP_CREDIT_TRANSACTION_BY_ID
  success: boolean
  payload: {
    error: Error
    requestParams: {
      whatsAppCreditTransactionID: number
    }
  }
}

export interface UpdateWhatsAppCreditTransactionAction {
  type: typeof types.UPDATE_WHATSAPP_CREDIT_TRANSACTION
  success: undefined
  payload: PutWhatsAppCreditTransactionRequestParams
}

export interface ResolveUpdateWhatsAppCreditTransactionAction {
  type: typeof types.UPDATE_WHATSAPP_CREDIT_TRANSACTION
  success: boolean
  payload: {
    whatsAppCreditTransaction: WhatsAppCreditTransaction
  }
}

export interface RejectUpdateWhatsAppCreditTransactionAction {
  type: typeof types.UPDATE_WHATSAPP_CREDIT_TRANSACTION
  success: boolean
  payload: PutWhatsAppCreditTransactionErrorResponse
}


export interface DeleteWhatsAppCreditTransactionAction {
  type: typeof types.DELETE_WHATSAPP_CREDIT_TRANSACTION
  success: undefined
  payload: {
    whatsAppCreditTransactionID: number
  }
}

export interface ResolveDeleteWhatsAppCreditTransactionAction {
  type: typeof types.DELETE_WHATSAPP_CREDIT_TRANSACTION
  success: boolean
  payload: {
    whatsAppCreditTransaction: WhatsAppCreditTransaction
  }
}

export interface RejectDeleteWhatsAppCreditTransactionAction {
  type: typeof types.DELETE_WHATSAPP_CREDIT_TRANSACTION
  success: boolean
  payload: WhatsAppCreditTransactionByIDErrorResponse
}

export interface CreateWhatsAppCreditTransactionAction {
  type: typeof types.CREATE_WHATSAPP_CREDIT_TRANSACTION
  success: undefined
  payload: PostWhatsAppCreditTransactionRequestParams
}

export interface ResolveCreateWhatsAppCreditTransactionAction {
  type: typeof types.CREATE_WHATSAPP_CREDIT_TRANSACTION
  success: boolean
  payload: {
    whatsAppCreditTransaction: WhatsAppCreditTransaction
  }
}

export interface RejectCreateWhatsAppCreditTransactionAction {
  type: typeof types.CREATE_WHATSAPP_CREDIT_TRANSACTION
  success: boolean
  payload: PostWhatsAppCreditTransactionErrorResponse
}

export const fetchWhatsAppCreditTransactions = (sorting: string, page: number, limit: number, search: string): FetchWhatsAppCreditTransactionsAction => ({
  type:    types.FETCH_WHATSAPP_CREDIT_TRANSACTIONS,
  success: undefined,
  payload: {
    sorting,
    page,
    limit,
    search,
  },
})

export const resolveFetchWhatsAppCreditTransactions = ({
                                        whatsAppCreditTransactions,
                                        paginationInfo,
                                        requestParams,
                                      }: WhatsAppCreditTransactionsResponse): ResolveFetchWhatsAppCreditTransactionsAction => ({
  type:    types.FETCH_WHATSAPP_CREDIT_TRANSACTIONS,
  success: true,
  payload: {
    whatsAppCreditTransactions,
    paginationInfo,
    requestParams,
  },
})

export const rejectFetchWhatsAppCreditTransactions = ({ error, requestParams }: WhatsAppCreditTransactionsErrorResponse): RejectFetchWhatsAppCreditTransactionsAction => ({
  type:    types.FETCH_WHATSAPP_CREDIT_TRANSACTIONS,
  success: false,
  payload: {
    error,
    requestParams,
  },
})

export const fetchWhatsAppCreditTransactionByID = (whatsAppCreditTransactionID: number): FetchWhatsAppCreditTransactionByIDAction => ({
  type:    types.FETCH_WHATSAPP_CREDIT_TRANSACTION_BY_ID,
  success: undefined,
  payload: {
    whatsAppCreditTransactionID,
  },
})

export const resolveFetchWhatsAppCreditTransactionByID = (whatsAppCreditTransaction: WhatsAppCreditTransaction): ResolveFetchWhatsAppCreditTransactionByIDAction => ({
  type:    types.FETCH_WHATSAPP_CREDIT_TRANSACTION_BY_ID,
  success: true,
  payload: {
    whatsAppCreditTransaction,
  },
})

export const rejectFetchWhatsAppCreditTransactionByID = ({
                                          error,
                                          requestParams,
                                        }: WhatsAppCreditTransactionByIDErrorResponse): RejectFetchWhatsAppCreditTransactionByIDAction => ({
  type:    types.FETCH_WHATSAPP_CREDIT_TRANSACTION_BY_ID,
  success: false,
  payload: {
    error,
    requestParams,
  },
})

export const updateWhatsAppCreditTransaction = (whatsAppCreditTransactionID: number, amount: number, customerID: number, notes: string | null): UpdateWhatsAppCreditTransactionAction => ({
  type:    types.UPDATE_WHATSAPP_CREDIT_TRANSACTION,
  success: undefined,
  payload: {
    whatsAppCreditTransactionID,
    amount,
    customerID,
    notes
  },
})

export const resolveUpdateWhatsAppCreditTransaction = (whatsAppCreditTransaction: WhatsAppCreditTransaction): ResolveUpdateWhatsAppCreditTransactionAction => ({
  type:    types.UPDATE_WHATSAPP_CREDIT_TRANSACTION,
  success: true,
  payload: {
    whatsAppCreditTransaction,
  },
})

export const rejectUpdateWhatsAppCreditTransaction = (payload: PutWhatsAppCreditTransactionErrorResponse): RejectUpdateWhatsAppCreditTransactionAction => ({
  type:    types.UPDATE_WHATSAPP_CREDIT_TRANSACTION,
  success: false,
  payload,
})


export const deleteWhatsAppCreditTransaction = (whatsAppCreditTransactionID: number): DeleteWhatsAppCreditTransactionAction => ({
  type:    types.DELETE_WHATSAPP_CREDIT_TRANSACTION,
  success: undefined,
  payload: {
    whatsAppCreditTransactionID,
  },
})

export const resolveDeleteWhatsAppCreditTransaction = (whatsAppCreditTransaction: WhatsAppCreditTransaction): ResolveDeleteWhatsAppCreditTransactionAction => ({
  type:    types.DELETE_WHATSAPP_CREDIT_TRANSACTION,
  success: true,
  payload: {
    whatsAppCreditTransaction,
  },
})

export const rejectDeleteWhatsAppCreditTransaction = (payload: WhatsAppCreditTransactionByIDErrorResponse): RejectDeleteWhatsAppCreditTransactionAction => ({
  type:    types.DELETE_WHATSAPP_CREDIT_TRANSACTION,
  success: false,
  payload,
})


export const createWhatsAppCreditTransaction = (amount: number, customerID: number, notes: string | null): CreateWhatsAppCreditTransactionAction => ({
  type:    types.CREATE_WHATSAPP_CREDIT_TRANSACTION,
  success: undefined,
  payload: {
    amount,
    customerID,
    notes
  },
})

export const resolveCreateWhatsAppCreditTransaction = (whatsAppCreditTransaction: WhatsAppCreditTransaction): ResolveCreateWhatsAppCreditTransactionAction => ({
  type:    types.CREATE_WHATSAPP_CREDIT_TRANSACTION,
  success: true,
  payload: {
    whatsAppCreditTransaction,
  },
})

export const rejectCreateWhatsAppCreditTransaction = (payload: PostWhatsAppCreditTransactionErrorResponse): RejectCreateWhatsAppCreditTransactionAction => ({
  type:    types.CREATE_WHATSAPP_CREDIT_TRANSACTION,
  success: false,
  payload,
})

export type WhatsAppCreditTransactionsActions =
  FetchWhatsAppCreditTransactionsAction
  | RejectFetchWhatsAppCreditTransactionsAction
  | ResolveFetchWhatsAppCreditTransactionsAction
  | FetchWhatsAppCreditTransactionByIDAction
  | ResolveFetchWhatsAppCreditTransactionByIDAction
  | RejectFetchWhatsAppCreditTransactionByIDAction
  | UpdateWhatsAppCreditTransactionAction
  | RejectUpdateWhatsAppCreditTransactionAction
  | ResolveUpdateWhatsAppCreditTransactionAction
  | CreateWhatsAppCreditTransactionAction
  | ResolveCreateWhatsAppCreditTransactionAction
  | RejectCreateWhatsAppCreditTransactionAction
  | DeleteWhatsAppCreditTransactionAction
  | ResolveDeleteWhatsAppCreditTransactionAction
  | RejectDeleteWhatsAppCreditTransactionAction
  | ClearFlashMessagesAction
  | LocationChangeAction
