export default class WhatsAppBranchSettings {
  branchID: number
  branchName: string
  invoiceAutoSendEnabled: boolean

  constructor(json: WhatsAppBranchSettingsJSON) {
    this.branchID = json.branchID
    this.branchName = json.branchName
    this.invoiceAutoSendEnabled = json.invoiceAutoSendEnabled
  }

  get [Symbol.toStringTag]() {
    return 'WhatsAppBranchSettings'
  }
}

export interface WhatsAppBranchSettingsJSON {
  branchID: number
  branchName: string
  invoiceAutoSendEnabled: boolean
}
