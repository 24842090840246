import { Money } from 'ts-money'
import Invoice, { InvoiceJSON, PaymentMethod } from '../model/Invoice'
import PaginationInfo from '../model/PaginationInfo'
import Payment, { PaymentFilters, PaymentJSON } from '../model/Payment'
import LoopError from '../store/errors/LoopError'
import { fetchWithErrors, HTTPMethods, newRequest, parseResponse, token, urlForEndpoint } from './helpers'
import { PaymentResponse, PostInvoicePaymentRequestParams, PutInvoicePaymentRequestParams } from './invoices'

export const getPayments = async (sorting: string = 'id', page: number = 1, limit: number = 30, filters: PaymentFilters, search: string): Promise<PaymentsResponse> => {

  // Prepare filters
  let flattenedFilters = {
    paymentMethod:  filters.paymentMethod?.id,
    paymentAccount: filters.paymentAccount,
    dateRange:      JSON.stringify(filters.dateRange),
    branch:         filters.branch,
  }
  if (filters.paymentMethod == null) {
    // @ts-ignore
    delete flattenedFilters.paymentMethod
  }
  if (filters.paymentAccount == null) {
    // @ts-ignore
    delete flattenedFilters.paymentAccount
  }
  if (filters.dateRange == null) {
    // @ts-ignore
    delete flattenedFilters.dateRange
  }
  if (filters.branch == null) {
    // @ts-ignore
    delete filters.branch
  }

  // Build request
  const url = urlForEndpoint(`payments`, {
    sorting,
    page,
    limit,
    search,
    ...flattenedFilters,
  })
  const request = newRequest(HTTPMethods.GET, token())

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const { payments: paymentsJSON, paginationInfo } = await parseResponse(response)

    let payments = paymentsJSON.map((paymentJSON: PaymentJSON) => new Payment(paymentJSON))

    return {
      payments,
      paginationInfo,
      requestParams: {
        sorting,
        page,
        limit,
        filters,
        search,
      },
    }

  } catch (err) {
    console.error(err)
    throw new LoopError(err, { sorting, page, limit, search })
  }
}

export const getPaymentByID = async (paymentID: number): Promise<Payment> => {

  // Build request
  const url = urlForEndpoint(`payments/${paymentID}`)

  const request = newRequest(HTTPMethods.GET, token())

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const { payment } = await parseResponse(response)
    return new Payment(payment as PaymentJSON)
  } catch (err) {
    throw new LoopError(err, { paymentID })
  }

}


export const postPayment = async (params: PostPaymentRequestParams): Promise<PostPaymentResponse> => {

  let { invoiceIDs, amount, paymentAccountID, transactionID } = params

  // Build request
  const url = urlForEndpoint(`payments`)
  const request = newRequest(HTTPMethods.POST, token())
  request.body = JSON.stringify({
    invoiceIDs,
    amount: amount.amount,
    paymentAccountID,
    transactionID,
  })

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const { invoices: invoicesJSON, payment: paymentJSON } = await parseResponse(response)

    let invoices = invoicesJSON.map((invoiceJSON: InvoiceJSON) => new Invoice(invoiceJSON))
    let payment = new Payment(paymentJSON as PaymentJSON)

    return { invoices, payment }
  } catch (err) {
    throw new LoopError(err, params)
  }

}

export const putPayment = async (params: PutPaymentRequestParams): Promise<Payment> => {

  let { paymentID, paymentAccountID, transactionID, notes } = params

  // Build request
  const url = urlForEndpoint(`payments/${paymentID}`)

  const request = newRequest(HTTPMethods.PUT, token())
  request.body = JSON.stringify({
    paymentAccountID,
    transactionID,
    notes: notes || null,
  })

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const { payment } = await parseResponse(response)
    return new Payment(payment as PaymentJSON)
  } catch (err) {
    throw new LoopError(err, params)
  }
}

export interface PaymentsResponse {
  payments: Payment[]
  paginationInfo: PaginationInfo
  requestParams: PaymentsRequestParams
}

export interface PaymentsErrorResponse {
  error: Error
  requestParams: PaymentsRequestParams
}

export interface PaymentsRequestParams {
  sorting: string
  page: number
  limit: number
  filters: PaymentFilters
  search: string
}

export interface PaymentByIDRequestParams {
  paymentID: number
}

export interface PaymentByIDErrorResponse {
  error: Error
  requestParams: PaymentByIDRequestParams
}

export interface PostPaymentRequestParams {
  paymentAccountID: number
  invoiceIDs: number[]
  amount: Money
  transactionID: string
}

export interface PutPaymentRequestParams {
  paymentID: number
  paymentAccountID: number
  notes?: string
  transactionID: string
}

export interface PutPaymentErrorResponse {
  error: Error
  requestParams: PutPaymentRequestParams
}

export interface PostPaymentErrorResponse {
  error: Error
  requestParams: PostPaymentRequestParams
}

export interface PostPaymentResponse {
  invoices: Invoice[]
  payment: Payment
}

