import WhatsAppCreditTransaction, { WhatsAppCreditTransactionJSON } from '../model/WhatsAppCreditTransaction'
import PaginationInfo from '../model/PaginationInfo'
import LoopError from '../store/errors/LoopError'
import { fetchWithErrors, HTTPMethods, newRequest, parseResponse, token, urlForEndpoint } from './helpers'

export const getWhatsAppCreditTransactions = async (sorting: string = 'id', page: number = 1, limit: number = 30, search: string): Promise<WhatsAppCreditTransactionsResponse> => {
  // Build request
  const url = urlForEndpoint(`whatsapp/credits/transactions`, {
    sorting,
    page,
    limit,
    search,
  })
  const request = newRequest(HTTPMethods.GET, token())

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const { whatsAppCreditTransactions: whatsAppCreditTransactionsJSON, paginationInfo } = await parseResponse(response)

    let whatsAppCreditTransactions = whatsAppCreditTransactionsJSON.map((whatsAppCreditTransactionJSON: WhatsAppCreditTransactionJSON) => new WhatsAppCreditTransaction(whatsAppCreditTransactionJSON))

    return {
      whatsAppCreditTransactions,
      paginationInfo,
      requestParams: {
        sorting,
        page,
        limit,
        search,
      },
    }

  } catch (err) {
    throw new LoopError(err, { sorting, page, limit, search })
  }
}

export const getWhatsAppCreditTransactionByID = async (whatsAppCreditTransactionID: number): Promise<WhatsAppCreditTransaction> => {

  // Build request
  const url = urlForEndpoint(`merchant-points/transactions/${whatsAppCreditTransactionID}`)

  const request = newRequest(HTTPMethods.GET, token())

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const { whatsAppCreditTransaction } = await parseResponse(response)
    return new WhatsAppCreditTransaction(whatsAppCreditTransaction as WhatsAppCreditTransactionJSON)
  } catch (err) {
    throw new LoopError(err, { whatsAppCreditTransactionID })
  }

}

export const putWhatsAppCreditTransaction = async (payload: PutWhatsAppCreditTransactionRequestParams): Promise<WhatsAppCreditTransaction> => {
  let { customerID, notes, amount, whatsAppCreditTransactionID } = payload

  // Build request
  const url = urlForEndpoint(`merchant-points/transactions/${whatsAppCreditTransactionID}`)

  const request = newRequest(HTTPMethods.PUT, token())
  request.body = JSON.stringify({
    customerID,
    notes,
    amount,
  })

  try {
    // Fetch
    const response = await fetchWithErrors(url, request)

    // Handle errors and return response
    const { whatsAppCreditTransaction } = await parseResponse(response)
    return new WhatsAppCreditTransaction(whatsAppCreditTransaction as WhatsAppCreditTransactionJSON)
  } catch (err) {
    throw new LoopError(err, payload)
  }
}

export const deleteWhatsAppCreditTransaction = async (whatsAppCreditTransactionID: number): Promise<WhatsAppCreditTransaction> => {
  // Build request
  const url = urlForEndpoint(`merchant-points/transactions/${whatsAppCreditTransactionID}`)

  const request = newRequest(HTTPMethods.DELETE, token())

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const { whatsAppCreditTransaction } = await parseResponse(response)
    return new WhatsAppCreditTransaction(whatsAppCreditTransaction as WhatsAppCreditTransactionJSON)
  } catch (err) {
    throw new LoopError(err, { whatsAppCreditTransactionID })
  }
}

export const postWhatsAppCreditTransaction = async (payload: PostWhatsAppCreditTransactionRequestParams): Promise<WhatsAppCreditTransaction> => {

  let { customerID, notes, amount } = payload

  // Build request
  const url = urlForEndpoint(`merchant-points/transactions`)

  const request = newRequest(HTTPMethods.POST, token())
  request.body = JSON.stringify({
    customerID, notes, amount,
  })

  try {
    // Fetch
    const response = await fetchWithErrors(url, request)

    // Handle errors and return response
    const { whatsAppCreditTransaction } = await parseResponse(response)
    return new WhatsAppCreditTransaction(whatsAppCreditTransaction as WhatsAppCreditTransactionJSON)
  } catch (err) {
    throw new LoopError(err, payload)
  }
}

export interface WhatsAppCreditTransactionIDsResponse {
  whatsAppCreditTransactionIDs: number[]
  requestParams: WhatsAppCreditTransactionsRequestParams
}


export interface WhatsAppCreditTransactionsResponse {
  whatsAppCreditTransactions: WhatsAppCreditTransaction[]
  paginationInfo: PaginationInfo
  requestParams: WhatsAppCreditTransactionsRequestParams
}

export interface WhatsAppCreditTransactionsErrorResponse {
  error: Error
  requestParams: WhatsAppCreditTransactionsRequestParams
}

export interface WhatsAppCreditTransactionsRequestParams {
  sorting: string
  page: number
  limit: number
  search: string
}

export interface WhatsAppCreditTransactionByIDRequestParams {
  whatsAppCreditTransactionID: number
}

export interface WhatsAppCreditTransactionByIDErrorResponse {
  error: Error
  requestParams: WhatsAppCreditTransactionByIDRequestParams
}

export interface PostWhatsAppCreditTransactionRequestParams {
  amount: number
  customerID: number
  notes: string | null
}

export interface PostWhatsAppCreditTransactionErrorResponse {
  error: Error
  requestParams: PostWhatsAppCreditTransactionRequestParams
}

export interface PutWhatsAppCreditTransactionRequestParams extends PostWhatsAppCreditTransactionRequestParams {
  whatsAppCreditTransactionID: number
}

export interface PutWhatsAppCreditTransactionErrorResponse {
  error: Error
  requestParams: PutWhatsAppCreditTransactionRequestParams
}